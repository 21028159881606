<template>
  <v-row>
    <v-col cols="12">
      <v-card
        min-width="100%"
        class="d-inline-block mx-auto"
        style="border-radius: 0"
        color="snow_gray"
        outlined
      >
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                v-model="form.default_address.vat_iso_prefix"
                class="prefix-column"
                :disabled="isFetchingCompanyData || loading"
                :label="$trans('tax_prefix')"
                outlined
                hide-details="auto"
                validate-on-blur
                :rules="[rules.maxChars(2)]"
                @change="emitAddress"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" class="text-center">
              <v-text-field
                v-model="form.default_address.tax_number"
                :disabled="isFetchingCompanyData || loading"
                :label="$trans('tax')"
                outlined
                validate-on-blur
                hide-details="auto"
                :rules="[rules.maxChars(15)]"
                @change="emitAddress"
              />
            </v-col>
            <v-col class="text-center" cols="12" md="4">
              <v-btn
                :loading="isFetchingCompanyData"
                :disabled="isFetchingCompanyData || loading"
                x-large
                color="secondary"
                @click="getCompanyData"
              >
                {{ $trans("get_company_data") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <v-col md="6" cols="12">
      <v-text-field
        v-model="form.default_address.name"
        :disabled="loading || isFetchingCompanyData"
        :rules="
          required
            ? [rules.required, rules.maxChars(255)]
            : [rules.maxChars(255)]
        "
        hide-details="auto"
        :label="$trans('company_name_2')"
        outlined
        @change="emitAddress"
      />
    </v-col>
    <v-col md="6" cols="12">
      <v-text-field
        v-model="form.default_address.street"
        :rules="
          required
            ? [rules.required, rules.maxChars(100)]
            : [rules.maxChars(100)]
        "
        :disabled="loading || isFetchingCompanyData"
        hide-details="auto"
        :label="$trans('street_and_number')"
        outlined
        @change="emitAddress"
      />
    </v-col>
    <v-col md="4" cols="12">
      <v-text-field
        v-model="form.default_address.postal_code"
        :disabled="loading || isFetchingCompanyData"
        :rules="
          required ? [rules.required, rules.maxChars(15)] : [rules.maxChars(15)]
        "
        hide-details="auto"
        :label="$trans('postcode')"
        outlined
        @change="emitAddress"
      />
    </v-col>
    <v-col md="4" cols="12">
      <v-text-field
        v-model="form.default_address.city"
        :rules="
          required ? [rules.required, rules.maxChars(50)] : [rules.maxChars(50)]
        "
        :disabled="loading || isFetchingCompanyData"
        hide-details="auto"
        :label="$trans('city')"
        outlined
        @change="emitAddress"
      />
    </v-col>
    <v-col md="4" cols="12">
      <c-country-autocomplete
        v-model="form.default_address.country_iso_code"
        :label="$trans('country')"
        :disabled="loading || isFetchingCompanyData"
        :rules="required ? [rules.required] : []"
        @input="emitAddress"
      />
    </v-col>
    <v-col cols="12">
      <slot name="summary" />
    </v-col>
    <v-col>
      <v-btn
        v-if="saveButton"
        color="primary"
        type="submit"
        :loading="loading"
        :disabled="loading || isFetchingCompanyData"
      >
        <span v-if="saveButtonText">{{ saveButtonText }} </span>
        <span v-else>{{ $trans("save") }}</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import CCountryAutocomplete from "@/components/Forms/CCountryAutocomplete";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";

export default {
  name: "CCompanyData",
  components: { CCountryAutocomplete },
  props: {
    myForm: {
      type: Object,
      default: null,
    },
    saveButton: {
      type: Boolean,
      default: true,
    },
    saveButtonText: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: { required, maxChars },
      isFetchingCompanyData: false,
      form: {
        default_address: {
          name: null,
          street: null,
          postal_code: null,
          city: null,
          country_iso_code: null,
          tax_number: null,
          vat_iso_prefix: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedUser: "user/getSelectedUser",
      getConfiguration: "setup/getConfiguration",
    }),
    taxIsoCode() {
      if (this.form.default_address.vat_iso_prefix) {
        return this.form.default_address.vat_iso_prefix;
      }
      if (this.getConfiguration.company_country_iso_code) {
        return this.getConfiguration.company_country_iso_code;
      }

      return this.getConfiguration.language;
    },
  },
  watch: {
    myForm: {
      deep: true,
      handler() {
        this.reload();
      },
    },
    "form.default_address.vat_iso_prefix": function (value) {
      this.form.default_address.vat_iso_prefix = value
        ? value.toUpperCase()
        : null;
    },
  },
  created() {
    this.reload();
  },
  methods: {
    ...mapActions({
      fetchCompanyData: "user/fetchCompanyData",
    }),
    getCompanyData() {
      this.isFetchingCompanyData = true;
      const companyData = {
        taxNumber: this.form.default_address.tax_number,
        taxCountryISOCode: this.taxIsoCode,
      };
      this.fetchCompanyData(companyData)
        .then((res) => {
          /* eslint-disable camelcase */
          const {
            city,
            name,
            postal_code,
            street,
            tax_number,
            country_iso_code,
          } = res;
          const obj = {
            city,
            name,
            postal_code,
            street,
            tax_number,
            country_iso_code,
          };
          obj.vat_iso_prefix = obj.country_iso_code;
          obj.country_iso_code = JSON.parse(
            JSON.stringify(obj.country_iso_code)
          ).toLowerCase();
          this.form.default_address = obj;
        })
        .catch((err) => {
          errorNotification("get_company_data_failed", err, false);
        })
        .finally(() => {
          this.isFetchingCompanyData = false;
        });
    },
    reload() {
      if (this.myForm) {
        this.reloadData(this.myForm);
        if (this.myForm.country_iso_code) {
          this.form.country_iso_code =
            this.myForm.country_iso_code.toLowerCase();
        }
      }
    },
    emitAddress() {
      this.$emit("defaultAddress", this.form.default_address);
    },
    reloadData(obj) {
      const path = this.form.default_address;
      if (obj && path) {
        for (const prop in obj) {
          if (obj[prop] || obj[prop] === "") path[prop] = obj[prop];
        }
      }
    },
  },
};
</script>
